import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/ministries.css"
import "../../styles/mary-and-marthas.css"
import JSONData from "../../../content/brothers-of-lazarus/data.json"
import OrgStructure from "../../../static/pdf/mary-and-marthas/Organizational Structure of Sisterhood.pdf"
import ProposalForm from "../../../static/pdf/mary-and-marthas/Request and Proposal Form.pdf"
import Bylaws from "../../../static/pdf/mary-and-marthas/Leadership Committee Bylaws.pdf"
import Prayers from "../../../static/pdf/mary-and-marthas/Sisters M & M General Meeting Prayers.pdf"

function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/brothers-of-lazarus/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	    }
	`






	let data = useStaticQuery(query)

	console.log(data)

	let images = data.allFile
	let sidebarData = JSONData.sidebarTextblocks;
	let subheaderData = JSONData.subheader; 

	const Paul = images.edges.find(element => element.node.name === 'Paul Cuts Tree').node
	const Sam = images.edges.find(element => element.node.name === 'Sam and Furnace').node

	const sidebarImage = images.edges.find(element => element.node.name === 'Bob Dyal at School').node
	const location = ""
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content mary-and-marthas">
				<div>
					<Subheader data={subheaderData} />
				</div>

					<Sidebar pageHome="true" textData={sidebarData} links={{}} image={sidebarImage}/>
					<div className="content">
					<div className="top-photos">
						<Img className="top-photo" fluid={Paul.childImageSharp.fluid}/>
						<Img className="top-photo" fluid={Sam.childImageSharp.fluid}/>
					
					</div>
						<h2>Method of Operation</h2>
						<ol>
							<li>
							Notification of a specific task will be sent by email from the Cathedral
							Maintenance Overseer to all the Brothers of Lazarus.
							</li>
							<li>
							Any member of the fellowship able to help with this task should respond by
							email, choosing “reply all,” so that other members will know that this task
							has been addressed. Members need respond only if they can help; they do
							not need to respond if they cannot help.
							</li>
							<li>							
							Once a member of the fellowship has offered to help with a task, the
							Maintenance Overseer will contact him with more specific details about the
							task before he begins to work. If no one is able to respond to a posted task,
							the Maintenance Overseer will find other means to address the task.
							</li>
							<li>							
							All members of the fellowship are encouraged to make suggestions to the
							Maintenance Overseer regarding maintenance needs they observe and to
							address on their own simple, minor issues they see. But members should not
							initiate any substantial task or incur any expense for reimbursement, without
							prior approval from the Maintenance Overseer.
							</li>
						</ol>
					<div className="download-btns">
						
					</div>
					</div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 